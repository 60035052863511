import * as React from 'react';
import { Link, HeadFC } from 'gatsby';
import Layout from 'components/Layout';

const NotFoundPage = () => {
  return (
    <Layout headerStyle="light">
      <div className="m-auto w-fit h-[100vh] px-[30px] ">
        <h1 className="mt-[84px]">Page not found</h1>
        <p>
          Sorry 😔, we couldn’t find what you were looking for.
          <br />
          <Link to="/">Go home</Link>.
        </p>
      </div>
    </Layout>
  );
};

export default NotFoundPage;

export const Head: HeadFC = () => <title>Page Not found</title>;
